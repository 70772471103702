<template>
  <router-view />
  <v-snackbar v-if="currentError" v-model="showErrorBar" color="error">
    {{ currentError.message }}
    <template #actions>
      <v-btn variant="tonal" @click="showErrorBar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { graphql } from "@/gql";
import { httpService } from "@/services/http-service";
import { useErrorStore } from "@/stores/error-store";
import type { AppError } from "@/stores/error-store";
import { useLoginStore } from "@/stores/login-store";
import { useTokenStore } from "@/stores/token-store";
import { useSubscription } from "@vue/apollo-composable";

import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

const showErrorBar = ref(false);
const errorStore = useErrorStore();
const currentError = ref<AppError | undefined>();
errorStore.onError(error => {
  currentError.value = error;
  showErrorBar.value = true;
});
const router = useRouter();
const tokenStore = useTokenStore();
const loginStore = useLoginStore();
onMounted(async () => {
  if (router.currentRoute.value.path == "/login") {
    return;
  }
  if (tokenStore.isLoggedIn && tokenStore.isJwtExpired())
    await httpService.refreshJwt();
});

router.beforeEach((to, from) => {
  if (to.name == "login" && loginStore.isLoggedIn) {
    const route = { path: tokenStore.nextLoginRedirect ? tokenStore.nextLoginRedirect : "/home" };
    tokenStore.nextLoginRedirect = '';
    return route;
  } else if (!loginStore.isLoggedIn && to.name != "login") {
    tokenStore.nextLoginRedirect = to.path;
    console.log("redirecting not logged in user to login");
    return { name: "login" };
  }
});

//language=graphql
const activeUserSubscription = useSubscription(graphql(
  `
    subscription ActiveUserSubscription($id: Int!) {
      mseagFinanceUsersByPk(id: $id) {
        id
        enabled
        approverUserId
        role
        receivableAccountId
      }
    }
  `
), () => ({ id: tokenStore.userId ?? 0 }), () => ({enabled: tokenStore.isLoggedIn}));
watch(activeUserSubscription.result, async () => {
  if (hasUserChangedFromToken()) {
    await httpService.refreshJwt();
  }
});

function hasUserChangedFromToken() {
  const user = activeUserSubscription.result.value?.mseagFinanceUsersByPk;
  if (!user) return false;
  return user.id != tokenStore.userId
    || user.role != tokenStore.userRoles[0]
    || !user.enabled
    || user.approverUserId != tokenStore.approverId;
}

</script>

<style scoped>

</style>
