import { apolloClient } from "@/apollo-client";
import { selectAllOnFocus } from "@/directives/selectAllOnFocus";
import { setupGlobals } from "@/globals";
import { router } from "@/router/router";
import { AppVersionNumber } from "@/version";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createApp } from "vue";

import { createVuetify } from "vuetify";
import { VTable } from "vuetify/components/VTable";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// Vuetify
import "vuetify/styles";

import App from "./App.vue";
import "./assets/main.css";

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const app = createApp(App);
setupGlobals(app);
app.provide(DefaultApolloClient, apolloClient);
app.directive('select-all-on-focus', selectAllOnFocus);
app
  .use(router)
  .use(
    createVuetify({
      components: { VTable },
      icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
          mdi
        }
      },
      theme: {
        themes: {
          light: {
            colors: {
              background: "#fafafa"
            }
          }
        }
      }
    })
  );
if (import.meta.env.PROD) {
  Sentry.init({
    app: app,
    logErrors: true,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    dsn: "https://1193aa924dcb4e34ad031b8368e1270d@o4504156820930560.ingest.sentry.io/4504156823945218",
    release: AppVersionNumber,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}
app.mount("#app");
