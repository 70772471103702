﻿import {
  MseagFinanceChannelTypeEnum,
  MseagFinanceExpenseReportLineItemStatusEnum,
  MseagFinanceExpenseReportLineItemTypeEnum,
  MseagFinanceExpenseReportStatusEnum
} from "@/gql/graphql";
import { currencyNameMap, displayCurrencyCode } from "@/services/currencies";
import { useTokenStore } from "@/stores/token-store";
import type { App } from "vue";

declare module "@vue/runtime-core" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface ComponentCustomProperties extends MseagCustomGlobals {}
}

class MseagCustomGlobals {
  $isNumber(this: void, value: string): boolean {
    return !isNaN(parseFloat(value));
  }
  $required(this: void, name: string) {
    return (value: unknown) => {
      if (typeof value == "string") {
        return value?.length !== 0 || `${name} is required`;
      }
      if (typeof value == "number") {
        return value !== 0 || `${name} is required`;
      }
      return (value !== null && value !== undefined) || `${name} is required`;
    };
  }

  $expenseTypeName(this: void, type: MseagFinanceExpenseReportLineItemTypeEnum) {
    switch (type) {
      case MseagFinanceExpenseReportLineItemTypeEnum.Cash:
        return "Cash Advance";
        break;
      case MseagFinanceExpenseReportLineItemTypeEnum.Expense:
        return "Expense";
        break;
      case MseagFinanceExpenseReportLineItemTypeEnum.Reimbursement:
        return "Reimbursement";
        break;
      case MseagFinanceExpenseReportLineItemTypeEnum.Rollover:
        return "Rollover"
        break;
      case MseagFinanceExpenseReportLineItemTypeEnum.Transfer:
        return "Transfer";
      case MseagFinanceExpenseReportLineItemTypeEnum.Payback:
        return "Payback";
    }
    return 'Unknown type: ' + type;
  }

  $currencyCode(this: void, currency: undefined) : undefined
  $currencyCode(this: void, currency: string) : string
  $currencyCode(this: void, currency: string|undefined) : string|undefined
  $currencyCode(this: void, currency: string|undefined) {
    return displayCurrencyCode(currency);
  }
  $expenseStatus = MseagFinanceExpenseReportLineItemStatusEnum
  $expenseType = MseagFinanceExpenseReportLineItemTypeEnum
  $reportStatus = MseagFinanceExpenseReportStatusEnum
  $channelType = MseagFinanceChannelTypeEnum
  $isDev = import.meta.env.DEV

  get $isAdmin(): boolean {
    //not used, setup below
    return false;
  }
  /*
  logged in user id
   */
  get $userId(): number | undefined {
    return 0;
    //not used, setup below
  }
}

export function setupGlobals(app: App) {
  const customGlobals = new MseagCustomGlobals();
  app.config.globalProperties.$isNumber = customGlobals.$isNumber;
  app.config.globalProperties.$required = customGlobals.$required;
  app.config.globalProperties.$expenseTypeName = customGlobals.$expenseTypeName;
  app.config.globalProperties.$currencyCode = customGlobals.$currencyCode;
  app.config.globalProperties.$expenseStatus = customGlobals.$expenseStatus;
  app.config.globalProperties.$expenseType = customGlobals.$expenseType;
  app.config.globalProperties.$reportStatus = customGlobals.$reportStatus;
  app.config.globalProperties.$channelType = customGlobals.$channelType;
  app.config.globalProperties.$isDev = customGlobals.$isDev;
  const tokenStore = useTokenStore();
  Object.defineProperty(app.config.globalProperties, '$isAdmin', {
    get(): boolean {
      return tokenStore.isAdmin;
    }
  });
  Object.defineProperty(app.config.globalProperties, "$userId", {
    get(): number | undefined {
      return tokenStore.userId;
    }
  });
}
