﻿import type { Directive } from "vue";

export const selectAllOnFocus: Directive<HTMLInputElement> = {
  created: (el, binding, vnode) => {
    for (const inputElement of Array.from(el.getElementsByTagName("input"))) {
      inputElement.addEventListener("focus", () => {
        inputElement.select();
      });
    }
  }
};